import Check from '@mui/icons-material/Check';
import { Box, Button, Dialog, DialogActions, DialogContent, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'

const CookieBanner = props => {

    const [show, setShow] = useState(false);
    const [more, setMore] = useState(false);

    const handleAccept = () => {
        setShow(false);
        setMore(false);
        localStorage.setItem('ca', new Date().toISOString());
    }

    useEffect(() => {

        const cookies = localStorage.getItem('ca');
        if(cookies === null)
            setShow(true);

    }, [])

    return (
        <React.Fragment>

            {show && <Box sx={{position: 'fixed', zIndex: 101, bottom: 0, left: 0, right: 0, width: '100%', backgroundColor: 'primary.main'}}>
                    
                    <Box sx={{p: 2, display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
                        <Box>
                            <Typography variant="subtitle2">Your privacy on Yupeto</Typography>
                            <Typography variant="caption" sx={{lineHeight: '1.3'}} component="div">Much like the rest of the internet, Yupeto stores cookies on your device to help deliver a functional service, but currently we DON'T store cookies in order to show relevant and personalised advertising.</Typography>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'flex-end', pt: 1}}>
                            <Button color="inherit" size="small" onClick={() => setMore(true)}>More</Button>
                            <Button color="secondary" variant="contained" size="small" onClick={handleAccept}>I accept</Button>
                        </Box>
                    </Box>

                </Box>
            }

            {more && 
                <Dialog open={true} fullWidth maxWidth="sm">
                    <DialogContent>
                        <Typography variant="h6">Your cookie options</Typography>
                        <List>
                            <ListItem alignItems='flex-start'>
                                <ListItemIcon sx={{minWidth: '38px'}}>
                                    <Check />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Essential Information"
                                    secondary="You cannot turn these cookies off because they are necessary to run the Yupeto service, for example to keep you signed in."
                                />
                            </ListItem>
                            <ListItem alignItems='flex-start'>
                                <ListItemIcon sx={{minWidth: '38px'}}>
                                    <Check />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Statistics"
                                    secondary="If we don't know it happened or can't measure the impact then we cannot fix it or make improvements. We access information that will describe your device, like the model and manufacturer but we never access any personal information from your device such as your name or email address."
                                />
                            </ListItem>
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" variant="contained" size="small" onClick={handleAccept}>I accept</Button>
                    </DialogActions>
                </Dialog>
            }

        </React.Fragment>
    )
}

export default CookieBanner