import React from 'react';
import Main from './src/context/main';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './src/global/theme';
import './src/global/main.css';



// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  //console.log("new pathname", location.pathname)
  //console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}


export const wrapPageElement = ({ element, props }) => {
  return (
    <Main {...props}>{element}</Main>
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  )
}