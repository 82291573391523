
import { createTheme } from '@mui/material/styles';


const palette = {
  type: 'light',
  primary: {
    main: '#FF5F5D',
    contrastText: '#fff',
    superLight: '#FFC8C7'
  },
  secondary: {
    main: '#3F7C85',
    contrastText: '#fff',
    superLight: '#d9e2e3'
  },
  success: {
    main: '#6CB33F',
    contrastText: '#fff'
  },
  error: {
    main: '#be2c00',
    contrastText: '#fff'
  },
  text: {
    primary: '#2C261D',
    contrastText: '#fff',
    secondary: '#747e7e'
  },
  background: {
    default: '#F4EFEF'
  },
  additional: {
    turquoise: '#00CCBF',
    grey: '#747E7E',
    yellow: '#F2B705',
    orange: '#F26835',
    lavender: '#E8D0F2',
    brown: '#8C5C03',
    bgDark: '#D6CDCD'
  }
};

const theme = createTheme({
  palette: palette,
  typography: {
    fontSize: 18,
    fontFamily: [
      'Quicksand', 
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '2.6rem',
      '@media (min-width:600px)': {
        fontSize: '3.4rem',
      },
      lineHeight: '1.1em',
      fontWeight: 700,
      marginBottom: 10
    },
    h2: {
      fontSize: '2.4rem',
      '@media (min-width:600px)': {
        fontSize: '2.9rem',
      },
      fontWeight: 700,
      marginBottom: 10
    },
    h3: {
      fontSize: '2.1rem',
      '@media (min-width:600px)': {
        fontSize: '2.6rem',
      }
    },
    h4: {
      fontSize: '1.8rem',
      '@media (min-width:600px)': {
        fontSize: '2.2rem',
      },
      fontWeight: 600
    },
    h5: {
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '1.8rem',
      },
      fontWeight: 600
    },
    h6: {
      fontSize: '1.2rem',
      '@media (min-width:600px)': {
        fontSize: '1.3rem',
      },
      lineHeight: '1.5em',
      fontWeight: 600
    },
    subtitle2: {
      fontWeight: 600
    },
    a: {
      color: palette.secondary.main
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: 60,
          borderTopLeftRadius: 60,
          borderBottomRightRadius: 60,
          borderBottomLeftRadius: 60,
          padding: '10px 26px',
          fontSize: '1rem',
          fontWeight: 600
        },
        sizeSmall: {
          borderTopRightRadius: 40,
          borderTopLeftRadius: 40,
          borderBottomRightRadius: 40,
          borderBottomLeftRadius: 40,
          padding: '4px 18px;',
          fontSize: '.8rem',
        },
        label: {
          textTransform: 'uppercase',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px'
        },
        containedSizeLarge: {
          padding: '12px 22px',
          fontSize: '1rem'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderBottomColor: 'transparent'
        }
      }
    },
    MuiSlider: {
      thumb: {
        color: palette.primary.contrastText,
        border: `2px solid ${palette.primary.main}`
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,.1)',
          marginTop: 20,
          marginBottom: 20
        }
      }
    },
    MuiList: {
      styleOverrides: {
        dense: {
          paddingTop: 4
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        dense: {
          paddingTop: 1,
          paddingBottom: 1
        }
      }
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true
      },
      styleOverrides: {
        root: {
          border: '1px solid rgba(0,0,0,.1)',
          borderRadius: 8
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.secondary.main,
          textDecorationColor: palette.secondary.main
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          '&:after': {
            borderBottomColor: theme.palette.secondary.main
          }
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          '&.Mui-focused': {
            color: theme.palette.secondary.main
          }
        })
      }
    },
    MuiFab: {
      styleOverrides: {
        sizeSmall: {
          width: '26px',
          height: '26px',
          lineHeight: '26px',
          minHeight: '26px'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20
        }
      }
    }
  }
})

export default theme;