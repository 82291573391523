import React, {useContext} from 'react';
import {Drawer, Box, IconButton, List, Typography, styled, ListItemButton, Divider, Link} from '@mui/material';
import globalContext from '../context/globalContext';
import CloseIcon from '@mui/icons-material/Close';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useLocation } from '@reach/router';
import Lock from '@mui/icons-material/Lock';
import { navigate } from 'gatsby';


const MainNav = styled(List)(({ theme }) => ({
  paddingTop: 20,
  paddingBottom: 20,
  '& .MuiListItemButton-root': {
    paddingLeft: 16,
    paddingRight: 16,
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent'
    }
  },
}));


const MainMenu = props => {

  const context = useContext(globalContext);
  const location = useLocation();
  const path = location.pathname.replace(/^\/|\/$/g, '');
  const activeSx = {backgroundColor: 'secondary.dark', color: 'secondary.contrastText', borderRadius: 2};

  const goTo = uri => {
    navigate(uri);
    context.toggleMenuOpen(false);
  }

  return (
    <Drawer
        anchor="right"
        open={props.open}
        onClose={() => context.toggleMenuOpen(false)}
      >
        <Box sx={{padding: 2, minWidth: 320, maxWidth: 500}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', marginBottom: 1}}>
                <IconButton onClick={() => context.toggleMenuOpen(false)} size="small"><CloseIcon sx={{fontSize: 22}} /></IconButton>
            </Box>
            
            <MainNav>
              <ListItemButton onClick={() => goTo('/')} sx={path === '' ? activeSx : {}}>Home</ListItemButton>
              <ListItemButton onClick={() => goTo('/why')} sx={path === 'why' ? activeSx : {}}>Why Yupeto</ListItemButton>
              <ListItemButton onClick={() => goTo('/pricing')} sx={path === 'pricing' ? activeSx : {}}>Pricing</ListItemButton>
              <ListItemButton onClick={() => goTo('/about')} sx={path === 'about' ? activeSx : {}}>About</ListItemButton>
              <ListItemButton onClick={() => goTo('/blog')} sx={path === 'blog' ? activeSx : {}}>Blog</ListItemButton>
              <ListItemButton onClick={() => goTo('/help')} sx={path === 'help' ? activeSx : {}}>Help</ListItemButton>
              <ListItemButton onClick={() => goTo('https://app.yupeto.com')}>Sign In</ListItemButton>
            </MainNav>
            <Divider />
            <Typography variant="caption" component={'div'} align="center" sx={{marginBottom: 1}}>Follow us</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'center'}}>
              <Link href="https://twitter.com/YupetoHQ" rel="nofollow" target="_blank" sx={{color: 'secondary.main'}}><TwitterIcon /></Link>
              <Link href="https://www.instagram.com/yupetohq/" rel="nofollow" target="_blank" sx={{color: 'secondary.main'}}><InstagramIcon /></Link>
            </Box>
        </Box>
    </Drawer>
  );
}

export default MainMenu;
