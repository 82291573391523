import React, {useState} from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { Box } from '@mui/material';
import AOS from 'aos';
import "aos/dist/aos.css";
import GlobalContext from './globalContext';
import MainMenu from '../content/mainMenu';
import CookieBanner from '../components/cookieBanner';



var firebaseConfig = {
  apiKey: "AIzaSyC5IyiDxxaKIbZ44xTv3d9hvsVtlMA0mNw",
  authDomain: "yupeto-11f2b.firebaseapp.com",
  projectId: "yupeto-11f2b",
  storageBucket: "yupeto-11f2b.appspot.com",
  messagingSenderId: "923017919937",
  appId: "1:923017919937:web:b3d7c41ada05f3b1f94340",
  measurementId: "G-TBZ86442Z5"
}

const fb = initializeApp(firebaseConfig);
const analytics = getAnalytics(fb);

//window.self.FIREBASE_APPCHECK_DEBUG_TOKEN=process.env.REACT_APP_CHECK_DEBUG;
// const appCheck = firebase.appCheck();
// appCheck.activate(process.env.REACT_APP_RECAPTCHA, true);

AOS.init();


const Main = props => {


  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1
    },
    inner: {
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }
  }

  const [menuOpen, setMenuOpen] = useState(false);


  return (
      <GlobalContext.Provider value={{
        ...props,
        toggleMenuOpen: setMenuOpen,
      }}>
        <MainMenu open={menuOpen} />

        <Box sx={styles.container}>
          <Box sx={styles.inner}>
            {props.children}
          </Box>
        </Box>

        <CookieBanner />
      </GlobalContext.Provider>
  );
}

export default Main;
